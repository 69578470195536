<template>
  <div>
    <!-- begin:: Header -->
    <div
      id="kt_header"
      ref="kt_header"
      class="header"
      v-bind:class="headerClasses"
    >
      <div
        class="container-fluid d-flex align-items-center justify-content-between"
      >
        <!-- begin:: Header Menu -->
        <div
          class="header-menu-wrapper header-menu-wrapper-left"
          ref="kt_header_menu_wrapper"
        >
          <div
            v-if="headerMenuEnabled"
            id="kt_header_menu"
            ref="kt_header_menu"
            class="header-menu header-menu-mobile"
            v-bind:class="headerMenuClasses"
          >
            <!-- example static menu here -->
            <ul
              class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
            >
              <template>
                <li class="breadcrumb-item">
                  <span style="font-size: 12px;">
                    TRANSAKSI BANK / BANK GARANSI EKSTERNAL / <span style="font-weight: 600;">UANG KELUAR</span>
                  </span>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <!-- end:: Header Menu -->
        <!-- begin:: Header Topbar -->
        <div class="topbar">
          <!--begin: User Bar -->
          <div class="topbar-item">
            <!--begin: Notifications -->
            <ApprovalNotification></ApprovalNotification>
            <!--end: Notifications -->
            <div
              class="btn btn-icon w-auto d-flex align-items-center btn-lg px-2"
              id="kt_quick_user_toggle"
            >
              <span
                class="symbol symbol-30 symbol-circle symbol-xl-30 pr-4 symbol-light-success"
              >
                <img v-if="false" alt="Profile Photo" :src="currentUserPhoto" />
                <span
                  v-if="true"
                  class="symbol-label font-size-h5 font-weight-bold"
                >
                  {{ user_fullname.charAt(0).toUpperCase() }}
                </span>
              </span>
              <span
                class="d-none d-md-inline mr-12"
                v-bind:style="{
                  'font-family': 'lato',
                  'font-size': '12px',
                  'font-weight': '600'
                }"
              >
                <span class="font-weight-bold font-size-base d-md-inline mr-1">
                  Hi,
                </span>
                {{ user_fullname }}
              </span>
              <i class="ki ki-arrow-down icon-xs text-muted mr-12"></i>
            </div>

            <div
              id="kt_quick_user"
              ref="kt_quick_user"
              class="offcanvas offcanvas-right p-10"
            >
              <!--begin::Header-->
              <div
                class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
              >
                <h3 class="font-weight-bold m-0">
                  PROFIL USER
                </h3>
                <a
                  href="#"
                  class="btn btn-xs btn-icon btn-light btn-hover-primary"
                  id="kt_quick_user_close"
                >
                  <i class="ki ki-close icon-xs text-muted"></i>
                </a>
              </div>
              <!--end::Header-->

              <!--begin::Content-->
              <perfect-scrollbar
                class="offcanvas-content pr-5 mr-n5 scroll"
                style="max-height: 90vh; position: relative;"
              >
                <!--begin::Header-->
                <div class="d-flex align-items-center mt-5">
                  <div class="symbol symbol-100 mr-5">
                    <img :src="currentUserPersonalInfo.photo" alt="" />
                    <i class="symbol-badge bg-success"></i>
                  </div>
                  <div class="d-flex flex-column">
                    <span class="font-weight-bold font-size-h5 text-dark-75">
                      {{ user_fullname }}
                    </span>
                    <div class="navi mt-2">
                      <span class="navi-link p-0 pb-2">
                        <span class="navi-icon mr-1">
                          <span class="svg-icon svg-icon-lg svg-icon-primary">
                            <!--begin::Svg Icon-->
                            <inline-svg src="assets/icons/Mail.svg" />
                            <!--end::Svg Icon-->
                          </span>
                        </span>
                        <span class="navi-text text-muted">
                          {{ email }}
                        </span>
                      </span>
                    </div>
                    <button
                      class="btn btn-light-primary btn-bold"
                      @click="onLogout"
                    >
                      SIGN OUT
                    </button>
                  </div>
                </div>
                <!--end::Header-->
                <div class="separator separator-dashed mt-8 mb-5"></div>
                <div class="separator separator-dashed my-7"></div>
              </perfect-scrollbar>
              <!--end::Content-->
            </div>
          </div>
          <!--end: User Bar -->
        </div>
        <!-- end:: Header Topbar -->
      </div>
    </div>
    <!-- end:: Header -->
    <!-- Body -->
    <div style="margin-bottom: 43px;">
      <div>
        <img class="mb-4" src="assets/background_page_title.png" alt="" style="height: 36px; margin-right: 10px;">
        <span style="font-size: 30px; font-weight: 700;">
          UANG KELUAR
        </span>
      </div>
      <div>
        <span>
          MENAMPILKAN DAN MEMBUAT DATA TRANSAKSI UANG KELUAR BARU
        </span>
      </div>
    </div>
    <div class="form-group">
      <template>
        <v-toolbar flat>
          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="white"
                light
                class="mb-12"
                v-bind="attrs"
                v-on="on"
                style="text-transform: capitalize !important; min-height: 48px; visibility: hidden; position: absolute;"
              >
                BUAT TRANSAKSI BARU +
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-col v-if="approvalStatus === 0">
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >PERUSAHAAN<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.workgroup_id"
                          id="workgroup_id"
                          :items="data_workgroup_combobox"
                          @click="dataWorkgroup_Combobox"
                          item-value="workgroup_id"
                          item-text="workgroup_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;">
                          PERUSAHAAN MITRA<span style="color: red;">*</span>
                        </span>
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.workgroup_partner_id"
                          id="workgroup_id"
                          :items="data_workgroup_combobox"
                          @click="dataWorkgroup_Combobox"
                          item-value="workgroup_id"
                          item-text="workgroup_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >PROYEK<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.project_id"
                          id="project_id"
                          :items="data_project_combobox"
                          @click="dataProject_Combobox"
                          item-value="project_id"
                          item-text="project_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                        <v-row>
                          <v-col cols="3" class="mt-4">
                            <span style="color: black; font-size: 14px;">
                              UPLOAD<span style="color: red;">*</span>
                            </span>
                          </v-col>
                          <v-col cols="6">
                            <v-file-input
                              accept="image/*"
                              type="file"
                              @change="onImageChange"
                              v-model="formInput.path_image_project"
                              show-size
                              counter
                              :clearable="false"
                            ></v-file-input>
                            <div
                              v-if="
                                this.add_internal.path_image_project2 != null
                              "
                            >
                              <img
                                :src="add_internal.path_image_project2"
                                style="max-width: 200px;"
                              />
                            </div>
                            <div v-else>
                              <img
                                :src="editedItem.path_image_project"
                                style="max-width: 200px;"
                              />
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >PEMBIAYAAN<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.financing_id"
                          id="financing_id"
                          :items="data_financings_combobox"
                          @click="dataFinancings_Combobox"
                          item-value="financing_id"
                          item-text="financing_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >COST<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.cost_financing_id"
                          id="cost_financing_id"
                          :items="data_cost_financings_combobox"
                          @click="dataCostFinancings_Combobox"
                          item-value="financing_id"
                          item-text="financing_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >BANK<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.bank_id"
                          id="bank_id"
                          :items="data_bank_combobox"
                          @click="dataBank_Combobox"
                          item-value="bank_id"
                          item-text="bank_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >NO REKENING<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-autocomplete
                          v-model="formInput.account_number"
                          id="account_number"
                          :items="data_bank_account_combobox"
                          @click="dataBankAccount_Combobox"
                          item-value="account_number"
                          item-text="account_number"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >KONTAK PERSON<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-text-field
                          v-model="formInput.contact_person"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-8">
                        <span style="color: black; font-size: 14px;"
                          >NOMINAL<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <vuetify-money
                          v-model="formInput.amount"
                          v-bind:placeholder="placeholder"
                          v-bind:readonly="readonly"
                          v-bind:disabled="disabled"
                          v-bind:outlined="outlined"
                          v-bind:clearable="clearable"
                          v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                          v-bind:options="option"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >TANGGAL TERBIT<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-menu
                          ref="date_published_internal"
                          v-model="date_published_internal"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          full-width
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formInputNotClear.date_published"
                              class="vuetify-custom"
                              solo
                              v-bind:style="{
                                color: '#c30e0e !important'
                              }"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date_published"
                            no-title
                            @input="date_published_internal = false"
                            @change="onChangeDatePublished(date_published)"
                            readonly
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >TANGGAL BERAKHIR<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-menu
                          ref="date_end_internal"
                          v-model="date_end_internal"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          full-width
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formInputNotClear.date_end"
                              class="vuetify-custom"
                              solo
                              v-bind:style="{
                                color: '#c30e0e !important'
                              }"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date_end"
                            no-title
                            @input="date_end_internal = false"
                            @change="onChangeDateEnd(date_end)"
                            readonly
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-else-if="approvalStatus == 1">
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >NO BANK GARANSI<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-text-field
                          v-model="formInput.guarantee_id"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  class="mb-4 btn btn-outline-secondary"
                  @click="close"
                >
                  BATALKAN
                </v-btn>
                <v-btn
                  color="primary"
                  light
                  class="mb-4"
                  @click="formSubmit"
                  style="text-transform: capitalize !important; color: white !important;"
                >
                  SIMPAN TRANSAKSI BARU
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="CARI TRANSAKSI"
            solo
            hide-details
            class="mb-12"
            style="width: 10px; box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);"
          ></v-text-field>
          <v-dialog v-model="dialogShowUploadBG" max-width="800px">
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-col>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;">
                          UPLOAD<span style="color: red;">*</span>
                        </span>
                      </v-col>
                      <v-col cols="9">
                        <v-file-input
                          accept="image/*"
                          type="file"
                          @change="onImageChangeBG"
                          v-model="formInput.path_image_bank_guarantee"
                          show-size
                          counter
                          :clearable="false"
                        ></v-file-input>
                        <div
                          v-if="
                            this.add_internal.path_image_bank_guarantee2 != null
                          "
                        >
                          <img
                            :src="add_internal.path_image_bank_guarantee2"
                            style="max-width: 200px;"
                          />
                        </div>
                        <div v-else>
                          <img
                            :src="editedItem.path_image_bank_guarantee"
                            style="max-width: 200px;"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialogShowUploadBG = false">
                  TUTUP
                </v-btn>
                <v-btn
                  color="primary"
                  light
                  @click="formSubmit"
                  style="text-transform: capitalize !important; color: white !important;"
                >
                  SIMPAN
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogSeenFile" max-width="800px">
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-col v-for="(data) in data_files" :key="data.submission_number">
                    <v-col v-if="data.path_image_project_extention != null">
                      <div>
                        <span class="text-h6">FOTO PENGAJUAN PROYEK</span>
                      </div>
                      <div class="text-center" v-if="data.path_image_project_extention == 'png' || data.path_image_project_extention == 'jpeg' || data.path_image_project_extention == 'jpg' || data.path_image_project_extention == 'gif'">
                        <img :src="data.path_image_project" alt="Foto" style="max-width: 700px;" />
                      </div>
                    </v-col>
                    <v-col v-if="data.path_image_bank_guarantee_extention != null">
                      <div>
                        <span class="text-h6">FOTO BANK GARANSI</span>
                      </div>
                      <div class="text-center" v-if="data.path_image_bank_guarantee_extention == 'png' || data.path_image_bank_guarantee_extention == 'jpeg' || data.path_image_bank_guarantee_extention == 'jpg' || data.path_image_bank_guarantee_extention == 'gif'">
                        <img :src="data.path_image_bank_guarantee" alt="Foto" style="max-width: 700px;" />
                      </div>
                    </v-col>
                  </v-col>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialogSeenFile = false">
                  Tutup
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >APAKAH YAKIN UNTUK MENGHAPUS DATA INI?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDelete">BATAL</v-btn>
                <v-btn
                  text
                  @click="deleteDataInternal"
                  v-bind:style="{
                    color: '#1e2758 !important'
                  }"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="data_internal"
          :item-key="bank_transaction_id"
          :options.sync="options"
          :search="search"
          sort-by="Id"
          class="elevation-1 mb-12"
          :loading="loadingTable"
          loading-text="HARAP TUNGGU..."
        >
          <template v-slot:[`item.number`]="{ index }">
            <td>
              {{
                options.page * options.itemsPerPage -
                  options.itemsPerPage +
                  (index + 1)
              }}
            </td>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <td>Rp {{ formatNumber(item.amount) }}</td>
          </template>
          <template v-slot:[`item.guarantee_id`]="{ item }">
            <td>
              {{ item.guarantee_id === "null" ? "-" : item.guarantee_id }}
            </td>
          </template>
          <template v-slot:[`item.financing_name`]="{ item }">
            <td>
              {{ item.financing_name === null ? "-" : item.financing_name }}
            </td>
          </template>
          <template v-slot:[`item.cost_financing_name`]="{ item }">
            <td>
              {{ item.cost_financing_name === null ? "-" : item.cost_financing_name }}
            </td>
          </template>
          <template v-slot:[`item.bank_name`]="{ item }">
            <td>
              {{ item.bank_name == null ? "-" : item.bank_name }}
            </td>
          </template>
          <template v-slot:[`item.account_number`]="{ item }">
            <td>
              {{ item.account_number == null ? "-" : item.account_number }}
            </td>
          </template>
          <template v-slot:[`item.date_published`]="{ item }">
            <td>
              {{ item.date_published == null ? "-" : item.date_published }}
            </td>
          </template>
          <template v-slot:[`item.date_end`]="{ item }">
            <td>
              {{ item.date_end == null ? "-" : item.date_end }}
            </td>
          </template>
          <template v-slot:[`item.path_image`]="{ item }">
            <v-btn
              color="blue"
              light
              small
              class="mr-3"
              @click="seenFile(item)"
              data-toggle="tooltip"
              title="LIHAT"
              style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
            >
              <v-icon small color="white">
                  mdi-eye
                </v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.aksi`]="{ item }">
            <v-btn
              color="green"
              light
              small
              class="mr-3 mt-2"
              @click="editItem(item)"
              data-toggle="tooltip"
              title="EDIT"
              style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
              v-if="(user_role.toLowerCase() === 'keuangan' && item.status_cashing === 0) || (user_role.toLowerCase() === 'atasan keuangan' && item.status_cashing === 0) || (user_role.toLowerCase() == 'keuangan kas kecil' && item.status_cashing === 0) || (user_role.toLowerCase() == 'master keuangan' && item.status_cashing === 0)"
            >
              <v-icon small color="white">
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn
              color="red"
              light
              small
              class="mt-2"
              @click="deleteItem(item)"
              data-toggle="tooltip"
              title="HAPUS"
              style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
              v-if="(user_role.toLowerCase() === 'keuangan' && item.status_cashing === 0) || (user_role.toLowerCase() === 'atasan keuangan' && item.status_cashing === 0) || (user_role.toLowerCase() == 'keuangan kas kecil' && item.status_cashing === 0) || (user_role.toLowerCase() == 'master keuangan' && item.status_cashing === 0)"
            >
              <v-icon small color="white">
                mdi-delete
              </v-icon>
            </v-btn>
            <v-btn
              color="blue"
              light
              small
              class="mr-3 my-2"
              @click="uploadBG(item)"
              data-toggle="tooltip"
              title="UPLOAD BG"
              style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
              v-if="(user_role.toLowerCase() === 'keuangan' && item.upload_status === 0) || (user_role.toLowerCase() === 'atasan keuangan' && item.upload_status === 0) || (user_role.toLowerCase() == 'keuangan kas kecil' && item.upload_status === 0) || (user_role.toLowerCase() == 'master keuangan' && item.upload_status === 0)"
            >
              <v-span small style="color: white;">
                UPLOAD BG
              </v-span>
            </v-btn>
            <v-btn color="blue" light small @click="updateDataInternalApproval(item)" style="text-transform: capitalize !important; color: white !important;" v-if="user_role.toLowerCase() === 'atasan keuangan' && item.status_cashing === 1 && item.approval_status === 0">
              APPROV
            </v-btn>
            <v-btn color="blue" light small @click="editItem(item)" data-toggle="tooltip" title="Edit" style="text-transform: capitalize !important; color: white !important; min-width: 0px; padding: 0 6px;" v-if="item.approval_status === 1 && item.allocation_status === 0">
              MASUKAN NO BANK GARANSI
            </v-btn>
            <v-btn
              color="blue"
              light
              small
              class="mb-2"
              @click="updateDataInternalPostStatus(item)"
              data-toggle="tooltip"
              title="POSTING"
              style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
              v-if="(user_role.toLowerCase() === 'atasan keuangan' && item.upload_status == 1 && item.allocation_status == 1 && item.post_status === 0)"
            >
              <v-span small style="color: white; padding: 0 7px 0 7px;">
                POSTING
              </v-span>
            </v-btn>
            <v-icon small color="black" v-if="item.post_status === 1">
              flaticon2-line
            </v-icon>
          </template>
        </v-data-table>
      </template>
    </div>
    <!-- End Body -->
  </div>
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<style scoped>
.vuetify-custom >>> .v-text-field__slot label {
  color: #1e2758 !important;
}

.vuetify-custom >>> .v-select__slot label {
  color: #1e2758 !important;
}

.vuetify-custom >>> .v-input__icon i {
  color: #1e2758 !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import ApprovalNotification from "@/view/pages/dashboard/ApprovalNotificationBell.vue";
import localStorage from "@/core/services/store/localStorage";
import Services from "@/core/services/app_internal/Services";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import "@/core/plugins/vuetify-money.js";
import { formatNumber } from "@/helpers/helper.js";
import { formatDate } from "@/helpers/helper.js";

export default {
  name: "internal",

  props: ["param_approval_status"],

  components: {
    ApprovalNotification
  },

  data: () => ({
    search: "",
    dialog: false,
    dialogShowUploadBG: false,
    dialogSeenFile: false,
    dialogDelete: false,
    create_data_internal: [],
    create_data_bank_guarantee: [],
    update_data_internal: [],
    delete_data_internal: [],
    create_data_voucher: [],
    data_internal: [],
    data_files: [],
    data_escrow_notification: [],
    data_bank_guarantee_notification: [],
    data_submission_form_finance_notification: [],
    data_workgroup_combobox: [],
    data_project_combobox: [],
    data_financings_combobox: [],
    data_cost_financings_combobox: [],
    data_bank_combobox: [],
    data_bank_account_combobox: [],
    data_internal_date: {
      date_published: new Date().toISOString().substr(0, 10),
      date_end: new Date().toISOString().substr(0, 10),
      date: new Date().toISOString().substr(0, 10)
    },
    add_internal: {
      workgroup_id: "",
      workgroup_partner_id: "",
      project_id: "",
      guarantee_id: "",
      financing_id: "",
      financing_name: "",
      cost_financing_id: "",
      cost_financing_name: "",
      bank_id: "",
      account_number: "",
      contact_person: "",
      amount: 0,
      date_published: new Date().toISOString().substr(0, 10),
      date_end: new Date().toISOString().substr(0, 10),
      path_image_project: null,
      path_image_project2: null,
      path_image_bank_guarantee: null,
      path_image_bank_guarantee2: null
    },
    add_internal_not_null: {
      date_published_internal: false,
      date_end_internal: false,
      date_published: formatDate(),
      date_end: formatDate()
    },
    submitted: false,
    headers: [],
    editedIndex: -1,
    deletedIndex: -1,
    editedItem: {},
    upload_bank_guarantee: {},
    deletedItem: {},
    defaultItem: {},
    isFirstLoad: true,
    options: {
      page: 1,
      itemsPerPage: 5,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: true,
      mustSort: false
    },
    label: "",
    placeholder: "",
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: false,
    valueWhenIsEmpty: "",
    option: {
      locale: "en-US",
      prefix: "",
      suffix: "",
      length: 20,
      precision: 0
    },
    date_published: "",
    date_end: "",
    date: "",
    user_role: "",
    seen: false,
    submissionNumber: "",
    user_id: "",
    user_fullname: "",
    email: "",
    count_escrow_notification: 0,
    count_bank_guarantee_notification: 0,
    count_submission_form_finance_notification: 0,
    loadingTable: true,
    approvalStatus: 0
  }),

  computed: {
    ...mapGetters([
      "layoutConfig",
      "getClasses",
      "currentUserPersonalInfo",
      "currentUserPhoto"
    ]),

    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    totalNotification: function() {
      let countEscrowNotification = this.count_escrow_notification;
      let countBankGuaranteeNotification = this
        .count_bank_guarantee_notification;
      let countSubmissionFormFinanceNotification = this
        .count_submission_form_finance_notification;
      let totalNotification = 0;

      totalNotification += parseInt(
        countEscrowNotification +
          countBankGuaranteeNotification +
          countSubmissionFormFinanceNotification
      );

      return totalNotification;
    },

    config() {
      return this.layoutConfig();
    },

    formTitle() {
      let result = "";

      if (this.editedIndex === -1) {
        result = "Buat Transaksi";
      } else if (this.editedIndex === -2) {
        result = "Upload Bank Garansi";
      } else {
        result = "Ubah Transaksi";
      }

      return result;
    },

    formInput() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.add_internal;
      } else if (this.editedIndex === -2) {
        result = this.upload_bank_guarantee;
      } else {
        result = this.editedItem;
      }

      return result;
    },

    formInputNotClear() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.add_internal_not_null;
      } else {
        result = this.editedItem;
      }

      return result;
    },

    formSubmit() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.createDataInternal;
      } else if (this.editedIndex === -2) {
        result = this.uploadBankGuarantee;
      } else {
        if (this.approvalStatus == 1) {
          result = this.updateDataInternalNoBankGuarantee;
        } else {
          result = this.updateDataInternal;
        }
      }

      return result;
    },

    computedDate() {
      return this.formatDate(this.date_published);
    },

    computedDateEnd() {
      return this.formatDate(this.date_end);
    },

    computedDateModified() {
      return this.formatDate(this.date);
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },

    options: {
      async handler(val) {
        val;
        if (!this.isFirstLoad) await this.dataDoMasuk();
        this.loading = false;
      },
      deep: true
    },

    date_published() {
      this.formInputNotClear.date_published = this.formatDate(
        this.date_published
      );
    },

    date_end() {
      this.formInputNotClear.date_end = this.formatDate(this.date_end);
    },

    date() {
      this.formInputNotClear.date = this.formatDate(this.date);
    }
  },

  created() {
    this.initHeders();
  },

  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );

    const headerRef = this.$refs["kt_header"];

    headerRef.querySelectorAll("a[class='menu-link']").forEach(item => {
      item.addEventListener("click", () => {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });

    this.load();

    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);

    this.user_id = localStorage.getLocalStorage("UID");
    this.user_fullname = localStorage.getLocalStorage("User_Fullname");
    this.email = localStorage.getLocalStorage("User_Email");
    this.user_role = localStorage.getLocalStorage("User_Role");

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Transaksi Uang Keluar" }]);
  },

  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },

    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },

    createImage(file) {
      let reader = new FileReader();

      reader.onload = e => {
        this.add_internal.path_image_project2 = e.target.result;
        this.count = this.add_internal.path_image_project2.length;
      };

      reader.readAsDataURL(file);
    },

    onImageChange(file) {
      this.createImage(file);
    },

    createImageBG(file) {
      let reader = new FileReader();

      reader.onload = e => {
        this.add_internal.path_image_bank_guarantee2 = e.target.result;
        this.count = this.add_internal.path_image_bank_guarantee2.length;
      };

      reader.readAsDataURL(file);
    },

    onImageChangeBG(file) {
      this.createImageBG(file);
    },

    getHeaders() {
      return [
        {
          text: "Id",
          sortable: false,
          value: "submission_number",
          align: " d-none"
        },
        { text: "NO", value: "number", width: "80px" },
        {
          text: "NO PENGAJUAN",
          sortable: true,
          value: "submission_number",
          width: "170px"
        },
        {
          text: "NO BANK GARANSI",
          sortable: true,
          value: "guarantee_id",
          width: "190px"
        },
        {
          text: "BANK",
          sortable: true,
          value: "bank_name",
          width: "110px"
        },
        {
          text: "NO REKENING",
          sortable: true,
          value: "account_number",
          width: "150px"
        },
        {
          text: "KATEGORI",
          sortable: true,
          value: "transaction_type",
          width: "130px"
        },
        {
          text: "PERUSAHAAN",
          sortable: true,
          value: "workgroup_name",
          width: "150px"
        },
        {
          text: "PERUSAHAAN MITRA",
          sortable: true,
          value: "workgroup_partner_name",
          width: "200px"
        },
        {
          text: "TGL PENGAJUAN",
          sortable: true,
          value: "date_submission",
          width: "170px"
        },
        {
          text: "TGL TERBIT",
          sortable: true,
          value: "date_published",
          width: "140px"
        },
        {
          text: "TGL BERAKHIR",
          sortable: true,
          value: "date_end",
          width: "160px"
        },
        {
          text: "PROYEK",
          sortable: true,
          value: "project_name",
          width: "110px"
        },
        {
          text: "PEMBIAYAAN",
          sortable: true,
          value: "financing_name",
          width: "150px"
        },
        {
          text: "COST",
          sortable: true,
          value: "cost_financing_name",
          width: "140px"
        },
        {
          text: "FILE FOTO",
          sortable: true,
          value: "path_image",
          width: "130px"
        },
        {
          text: "KONTAK PERSON",
          sortable: true,
          value: "contact_person",
          width: "170px"
        },
        {
          text: "NOMINAL",
          sortable: true,
          value: "amount",
          width: "120px"
        },
        {
          text: "EDIT",
          value: "aksi",
          sortable: false,
          width: "110px"
        }
      ];
    },

    loadDate: function() {
      this.date_published = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.date_published = this.formatDate(new Date().toISOString().substr(0, 10));
    },

    loadDateEnd: function() {
      this.date_end = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.date_end = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    loadDateModified: function() {
      this.date = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.date = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    formatDate(date) {
      if (!date) return null;

      var arr = date.split("-"),
        year = arr[0],
        month = arr[1],
        day = arr[2];
      return `${day}-${month}-${year}`;
    },

    parseDate: function(date) {
      if (!date) return null;

      var arr = date.split("/"),
        day = arr[0],
        month = arr[1],
        year = arr[2];
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    onChangeDatePublished(item) {
      this.$refs.date_published_internal.save(item);
      this.data_internal_date.date_published = item;
    },

    onChangeDateEnd(item) {
      this.$refs.date_end_internal.save(item);
      this.data_internal_date.date_end = item;
    },

    createDataInternal() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "C",
          Token: "C",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          workgroup_id: this.add_internal.workgroup_id,
          project_id: this.add_internal.project_id,
          guarantee_id: this.add_internal.guarantee_id,
          bank_id: this.add_internal.bank_id,
          account_number: this.add_internal.account_number,
          amount: this.add_internal.amount,
          date_published: this.data_internal_date.date_published,
          date_end: this.data_internal_date.date_end,
          bank_transaction_date: this.data_internal_date.date,
          bank_transaction_permission: "BGJ",
          bank_transaction_type: "I",
          created_by: localStorage.getLocalStorage("User_Fullname"),
          approval_status: 0,
          upload_status: 0,
          status: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MENAMBAH DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.create_data_internal = response.data;
            this.submitted = true;
            this.save("add_bank_guarantee");
            this.createDataBankGuarantee();
            this.load();
          },
          err => {
            err;
          }
        );
      });
    },

    createDataBankGuarantee(item) {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "C",
          Token: "C",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          guarantee_id: item.guarantee_id,
          workgroup_id: item.workgroup_id,
          project_id: item.project_id,
          bank_id: item.bank_id,
          account_number: item.account_number,
          guarantee_permission: "BGJ",
          guarantee_type: "EUK",
          guarantee_date: this.data_internal_date.date,
          total_amount: item.amount,
          total_paid: 0,
          total_received: 0,
          date_published: this.data_internal_date.date_published,
          date_end: this.data_internal_date.date_end,
          created_by: localStorage.getLocalStorage("User_Fullname"),
          status_cashing: 0,
          upload_status: 1,
          status: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankGuarantee",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.create_data_bank_guarantee = response.data;
            this.submitted = true;
            this.loadDataBankGuaranteeNotification();
          },
          err => {
            err;
          }
        );
      });
    },

    createDataVoucherBankIn(item) {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "C",
          Token: "C",
          UID: localStorage.getLocalStorage("UID"),
          Route: "MUTATION",
          workgroup_id: item.workgroup_id,
          project_id: item.project_id,
          bank_id: item.bank_id,
          account_number: item.account_number,
          guarantee_id: item.guarantee_id,
          tax_invoice_number: "",
          invoice_number: "",
          street_mail_number: "",
          bank_transaction_desc: "",
          bank_transaction_date: this.data_internal_date.date,
          bank_transaction_permission: "BM",
          amount: item.amount,
          created_by: localStorage.getLocalStorage("User_Fullname"),
          path_image_evidence_of_transfer: ".",
          approval_status: 0,
          upload_status: 0,
          status: 1
        };

        var postdata = new FormData();

        for (var key in mydata) {
          postdata.append(key, mydata[key]);
        }

        let contentType = `multipart/form-data; boundary=${postdata._boundary}`;

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          postdata,
          contentType,
          response => {
            resolve(response.data);
            this.create_data_voucher = response.data;
            this.submitted = true;
          },
          err => {
            err;
          }
        );
      });
    },

    updateDataInternal() {
      return new Promise(resolve => {
        var datesubmission = this.editedItem.date_submission;
        datesubmission = datesubmission.split("-");

        var DD = datesubmission[0];
        var MM = datesubmission[1];
        var YY = datesubmission[2];

        var format_date_submission = YY + "-" + MM + "-" + DD;

        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "CASH_OUT",
          submission_number: this.editedItem.submission_number,
          workgroup_id: this.editedItem.workgroup_id,
          workgroup_partner_id: this.editedItem.workgroup_partner_id,
          project_id: this.editedItem.project_id,
          financing_id: this.editedItem.financing_id,
          cost_financing_id: this.editedItem.cost_financing_id,
          bank_id: this.editedItem.bank_id,
          account_number: this.editedItem.account_number,
          guarantee_id: this.editedItem.guarantee_id,
          transaction_type: "JBD",
          date_submission: format_date_submission,
          submission_type: "BGOE",
          submission_permission: "OI",
          contact_person: this.editedItem.contact_person,
          amount: this.editedItem.amount,
          submission_status: this.editedItem.submission_status,
          path_image_project: this.editedItem.path_image_project,
          path_image_bank_guarantee: this.editedItem.path_image_bank_guarantee != "." ? this.editedItem.path_image_bank_guarantee != "" ? this.editedItem.path_image_bank_guarantee : "http://emitra.asiatechintiraya.id/images/bank-guarantee/." : "http://emitra.asiatechintiraya.id/images/bank-guarantee/.",
          modified_by: localStorage.getLocalStorage("User_Fullname"),
          date_modified: this.data_internal_date.date,
          date_published: this.data_internal_date.date_published,
          date_end: this.data_internal_date.date_end,
          status_cashing: 1,
          status: 1
        };

        var postdata = new FormData();

        for (var key in mydata) {
          postdata.append(key, mydata[key]);
        }

        let contentType = `muntipart/forn-data; boundary=${postdata._boundary}`;

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          postdata,
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.update_data_internal = response.data;
            this.submitted = true;
            this.save("edit_internal");
            this.loadDataInternal();
          },
          err => {
            err;
          }
        );
      });
    },

    uploadBankGuarantee() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "UPLOAD_BANK_GUARANTEE",
          submission_number: this.upload_bank_guarantee.submission_number,
          destination: "bank-guarantee",
          path_image_bank_guarantee: this.upload_bank_guarantee
            .path_image_bank_guarantee,
          upload_status: 1
        };

        var postdata = new FormData();

        for (var key in mydata) {
          postdata.append(key, mydata[key]);
        }

        let contentType = `muntipart/forn-data; boundary=${postdata._boundary}`;

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          postdata,
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.update_data_internal = response.data;
            this.submitted = true;
            this.save("edit_internal");
            this.dialogShowUploadBG = false;
            this.loadDataInternal();
          },
          err => {
            err;
          }
        );
      });
    },

    updateDataInternalApproval(item) {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "APPROVAL_BANK_GUARANTEE",
          submission_number: item.submission_number,
          approval_by: localStorage.getLocalStorage("User_Fullname"),
          approval_status: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.update_data_internal = response.data;
            this.submitted = true;
            this.save("edit_internal");
            this.loadDataInternal();
          },
          err => {
            err;
          }
        );
      });
    },

    updateDataInternalNoBankGuarantee() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "ADD_NO_BANK_GUARANTEE",
          submission_number: this.editedItem.submission_number,
          guarantee_id: this.editedItem.guarantee_id,
          allocation_status: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        let item = this.editedItem;

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.update_data_internal = response.data;
            this.submitted = true;
            this.save("edit_internal");
            this.loadDataInternal();
          },
          err => {
            err;
          }
        );
      });
    },

    updateDataInternalPostStatus(item) {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "POSTED",
          submission_number: item.submission_number,
          posted_by: localStorage.getLocalStorage("User_Fullname"),
          date_posted: this.data_internal_date.date,
          post_status: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.update_data_internal = response.data;
            this.submitted = true;
            this.save("edit_internal");
            this.asyncCreateDataBankGuarantee(item);
          },
          err => {
            err;
          }
        );
      });
    },

    deleteDataInternal() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "D",
          Token: "D",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          submission_number: this.deletedItem.submission_number
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MENGHAPUS DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.delete_data_internal = response.data;
            this.submitted = true;
            this.data_internal.splice(this.deletedIndex, 1);
            this.closeDelete();
          },
          err => {
            err;
          }
        );
      });
    },

    dataInternal() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT_BANK_GUARANTEE",
          submission_type: "BGOE",
          submission_permission: "OI",
          approval_status: this.param_approval_status ? this.param_approval_status : "",
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_internal = response.data;
            this.loadingTable = false;
          },
          err => {
            err;
          }
        );
      });
    },

    dataFile(item) {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "BANK_GUARANTEE_FILE",
          submission_number: item.submission_number,
          submission_type: "BGOE",
          submission_permission: "OI"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_files = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataEscrowPaymentAccepted() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "ESCROW_PAYMENT_ACCEPTED",
          payment_accepted: "E",
          status_receipt: 1,
          status_escrow_accepted: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_escrow_notification = response.data;
            this.count_escrow_notification =
              this.user_role.toLowerCase() === "atasan keuangan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    dataBankGuaranteeNotification() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "NOTIFICATION_BANK_GUARANTEE",
          status_cashing: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankGuarantee",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_bank_guarantee_notification = response.data;
            this.count_bank_guarantee_notification =
              this.user_role.toLowerCase() === "atasan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    dataSubmissionFormNotification() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "NOTIFICATION_FOR_BANK_GUARANTEE",
          submission_type: "BGOI",
          status_cashing: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_submission_form_finance_notification = response.data;
            this.count_submission_form_finance_notification =
              this.user_role.toLowerCase() === "atasan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    dataWorkgroup_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Workgroup",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_workgroup_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataProject_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Project",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_project_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataBank_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Bank",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_bank_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataBankAccount_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX",
          bank_id: this.formInput.bank_id
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/BankAccount",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_bank_account_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataFinancings_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX",
          financing_type: "Pembiayaan"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Financings",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_financings_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataCostFinancings_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX",
          financing_type: "Cost"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Financings",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_cost_financings_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    async load() {
      Promise.all([
        // await this.dataEscrowPaymentAccepted(),
        // await this.dataBankGuaranteeNotification(),
        // await this.dataSubmissionFormNotification(),
        await this.dataInternal()
      ]).then(function(results) {
        results;
      });
    },

    async loadDataInternal() {
      Promise.all([await this.dataInternal()]).then(function(results) {
        results;
      });
    },

    async loadDataBankGuaranteeNotification() {
      Promise.all([
        await this.dataBankGuaranteeNotification(),
        await this.dataInternal()
      ]).then(function(results) {
        results;
      });
    },

    async loadCombobox() {
      Promise.all([
        await this.dataWorkgroup_Combobox(),
        await this.dataProject_Combobox()
      ]).then(function(results) {
        results;
      });
    },

    async asyncCreateDataBankGuarantee(item) {
      Promise.all([await this.createDataBankGuarantee(item)]).then(function(
        results
      ) {
        results;
      });
    },

    uploadBG(item) {
      this.editedIndex = -2;
      this.upload_bank_guarantee = Object.assign({}, item);
      this.submissionNumber = item.submission_number;
      this.dialogShowUploadBG = true;
    },

    seenFile(item) {
      this.dataFile(item);
      this.dialogSeenFile = true;
    },

    editItem(item) {
      this.editedIndex = this.data_internal.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.approvalStatus = item.approval_status;
      this.loadCombobox();
      this.dialog = true;
    },

    deleteItem(item) {
      this.deletedIndex = this.data_internal.indexOf(item);
      this.deletedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.data_internal.splice(this.deletedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.add_internal.workgroup_id = "";
        this.add_internal.workgroup_partner_id = "";
        this.add_internal.project_id = "";
        this.add_internal.bank_id = "";
        this.add_internal.account_number = "";
        this.add_internal.contact_person = "";
        this.add_internal.amount = 0;
        this.add_internal.path_image_project = null;
        this.add_internal.path_image_project2 = null;
        this.add_internal.path_image_bank_guarantee = null;
        this.add_internal.path_image_bank_guarantee2 = null;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.upload_bank_guarantee = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deletedItem = Object.assign({}, this.defaultItem);
        this.deletedIndex = -1;
      });
    },

    save(formInput) {
      if (formInput == "add_internal") {
        if (this.editedIndex > -1) {
          Object.assign(
            this.data_internal[this.editedIndex],
            this.add_internal
          );
        } else {
          this.data_internal.push(this.add_internal);
        }

        this.close();
      } else if (formInput == "edit_internal") {
        if (this.editedIndex > -1) {
          Object.assign(this.data_internal[this.editedIndex], this.editedItem);
        } else {
          this.data_internal.push(this.editedItem);
        }

        this.close();
      } else if (formInput == "add_bank_guarantee") {
        this.close();
      } else if (formInput == "upload_bank_guarantee") {
        if (this.editedIndex > -1) {
          Object.assign(
            this.data_internal[this.editedIndex],
            this.upload_bank_guarantee
          );
        } else {
          this.data_internal.push(this.upload_bank_guarantee);
        }
      }
    },

    initHeders() {
      this.headers = this.getHeaders();
    },

    formatNumber
  }
};
</script>
